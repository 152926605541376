<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="详情" name="first">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_01.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_02.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_03.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_04.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_05.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_06.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_07.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_08.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_09.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_10.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_11.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_12.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_13.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_14.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_15.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_16.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_17.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86_18.jpg" lazy width="100%" height="100%">
      </el-tab-pane>
      <el-tab-pane label="规格" name="second">
        <img src="../../../../../public/images/相机详情/室外摄像机（300万像素）/Z86室外wifi机/Z86规格.jpg" lazy width="100%" height="100%">
      </el-tab-pane>
      <el-tab-pane label="视频" name="third">
        <video src="../../../../../public/images/三级页面/视频样例/此视频借用到产品CS48Q视频.mp4"  width="100%" controls="controls"></video>
      </el-tab-pane>
      <el-tab-pane label="技术支持" name="fourth">

      </el-tab-pane>
      <el-tab-pane label="扩展" name="kz">

      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Z86",
  data() {
    return {
      activeName: 'first'
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped>
.tabs{
  margin-top: 100px;
}
/deep/.el-tabs__item{
  font-size: 16px;
}
</style>